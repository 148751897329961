@include screen(lg) {
	.panoramic__view {
		&.jcp-construction {
			background-image: url('../images/mobile-jcp-construction.jpg') !important;
		}
		&.law-tanning {
			background-image: url('../images/mobile-law-tanning.jpg') !important;
		}
		&.kummrow-automotive {
			background-image: url('../images/mobile-kummrow-automotive.jpg') !important;
		}
		&.eagle-enterprises {
			background-image: url('../images/mobile-eagle-enterprises.jpg') !important;
		}
		&.engel-tool-forge {
			background-image: url('../images/mobile-engel-tool-forge.jpg') !important;
		}
		&.three-sixty {
			background-image: url('../images/mobile-three-sixty.jpg') !important;
		}
		&.sign-effectz {
			background-image: url('../images/mobile-sign-effectz.jpg') !important;
		}
		&.bittercube-bitters {
			background-image: url('../images/mobile-bittercube-bitters.jpg') !important;
		}
	}
}