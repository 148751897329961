.primary-button {
	// width: 100%;
	// max-width: _rem(220);
	padding: 1.1rem 2rem;
	border-radius: .25rem;
	font-size: 1.25rem;
	font-weight: 700;
	text-decoration: none;
	letter-spacing: 0.015em;
	border: none;
	display: inline-block;
	margin-top: 1rem;

	&.alpha {
		background-color: $alpha-md;
		color: #fff;
	}

	&.beta {
		background-color: $beta-md;
		color: #fff;
	}


	&.play, &.external {
		position: relative;
		padding-right: 3rem;

		&:after {
			content:'';
			width: 1.5rem;
			height: 1.5rem;
			background-size: cover;
			position: absolute;
			top: 50%;
			transform: translate( 0, -50%);
			transition: all 0.25s ease-out;
			right: 1.25rem;
		}

		&:hover {
			&:after {
				transform: translate( .5rem, -50%);
			}
		}
	}

	&.play:after {
		background: url('../images/graphic-button-playhead.svg') center center no-repeat;
		@include screen(sm) {
			width: 2rem;
			height: 2rem;
		}
	}

	&.external:after {
		right: 1rem;
		background: url('../images/graphic-button-arrow.svg') center center no-repeat;
	}

	@include screen(sm) {
		width: 100%;

		&.button-list {
			margin-bottom: 1rem;
		}
	}
}

