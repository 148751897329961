.panoramic {
	width: 100%;
	overflow-x: hidden;

	&__container {
		width: 200%;
		
		display: flex;
		flex-direction: row;
		transition: transform 0.3s cubic-bezier(.3,.77,.58,.99);
		will-change: transform;

		&.active {
			transform: translatex( -50% );
		}

		@include screen(lg) {
			min-height: 100vh;
		}
	}

	&__close {
		position: absolute;
		top: 1.25rem;
		right: 3rem;
		width: 2rem;
		height: 2rem;
		background: url('../images/graphic-close.svg') center center no-repeat;
		background-size: 2rem;
		appearance: none;
		border: none;
		transition: transform 0.2s ease-in-out;

		&:hover {
			transform: translateY(-.25rem);
		}
	}

	&__view {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 1;
		padding: 4rem 0;

		&.gradient {
			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgb(11,72,91);
				background: linear-gradient(-90deg, rgba(11,72,91,.8) 0%, rgba(11,72,91,0) 100%);
				z-index: -1;
			}
		}

		@include screen(md) {
			padding: 0;
		}
	}

	.video-slide {
		max-width: _rem(775);
		margin: 0 auto;
	}

	&__video-message {
		text-align: center;
		padding-top: 1rem;

		p {
			margin-bottom: 0;
			font-size: _rem(14);
		}

		a.secondary-button {
			font-size: _rem(14);
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	&:nth-child(odd) {
		.panoramic__view {
			&.gradient:before {
				background: linear-gradient(90deg, rgba(11,72,91,.8) 0%, rgba(11,72,91,0) 100%);
			}
		}
	}

	@include screen(sm) {
		&__view {
			&.gradient:before {
				background: linear-gradient(180deg, rgba(11,72,91,.8) 0%, rgba(11,72,91,0) 100%) !important;
			}
		}
	}
}