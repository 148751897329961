.menu {
	position: fixed;
	right: 1rem;
	top: 0;
	height: 100%;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__progress {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 1px;
		background: rgba(white, 0);
		height: 100%;
	}

	&__nodes {
		position: relative;
	}

	&__node {
		display: block;
		position: relative;
		z-index: 1;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	$dot-size: 1.25rem;

	&__slide {
		position: absolute;
		right: $dot-size;
		top: 50%;
		transform: translate( 0, -50% );
		color: #fff;
		white-space: nowrap;
		text-transform: uppercase; 
		font-size: .75rem;
		opacity: 0;
		z-index: -1;
		transition: all 0.3s ease-out;
	}

	&__dot {
		width: $dot-size;
		height: $dot-size;
		border-radius: $dot-size;
		background: #fff;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: all 0.15s ease-out;
		transform: scale(1);

		.core {
			transition: all 0.15s ease-out;
			transform: scale(0);
		}

		&.active {
			.core {
				transition: all 0.35s ease-out;
				width: $dot-size * .7;
				height: $dot-size * .7;
				border-radius: $dot-size * .7;
				background: $alpha-md;
				transform: scale(1);
			}
		}

		&:hover, &.reveal {
			transform: scale(1.1);
			& ~ .menu__slide {
				opacity: 1;
				transform: translate( -1rem, -50% );
			}
		}
	}

	@include screen(lg) {
		display: none;
	}
}