.video-player {
	position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0;
    height: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000;
    z-index: 1;
    cursor: pointer;

    &:not(.loaded) {
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5rem;
            height: 5rem;
            border-radius: 5rem;
            background: url('../images/graphic-button-playhead.svg') rgba(black, 0.6) center right .75rem no-repeat;
            background-size: 3rem;
        }
    }

	&__media, iframe {
		position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
	}
}