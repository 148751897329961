a.logo {
	display: block;
	width: 13.6rem;
	height: 2.5rem;
	background: url('../images/logo-zoro.svg') center center no-repeat;
	background-size: cover;
	cursor: pointer;

	&.mono {
		background-image: url('../images/logo-zoro-gs.svg');
	}
}