h1, h2, h3, h4, h5, h6 {
	margin: 0;
}
.h2 {
	font-size: _rem(40);
	font-weight: 600;
	line-height: _leading(40, 55);
	letter-spacing: _tracking(10);

	&.tight {
		line-height: _leading(40, 40);
	}

	@include screen(sm) {
		font-size: _rem(45);
		line-height: _leading(45, 40);

		&.long {
			font-size: _rem(22);
			line-height: _leading(22, 34);
		}
	}
}

.h3 {
	font-size: _rem(23);
	font-weight: 700;
	line-height: _leading(23, 30);
	letter-spacing: _tracking(0);
	margin-top: 1.5rem;

	@include screen(sm) {
		margin-top: 1rem;
		font-size: _rem(18);
		line-height: _leading(18, 26);
	}
}

p {
	font-size: _rem(18);
	font-weight: 300;
	line-height: _leading(20, 26);
	letter-spacing: _tracking(10);
	margin: .85rem 0 .95rem 0;
	padding: 0;

	@include screen(sm) {
		font-size: _rem(24);
		line-height: _leading(24, 30);
	}

}

.extra-bottom {
	margin-bottom: _rem(40);
}

.nbws {
	white-space: nowrap;
	&.xm {
		@include screen(md) {
			white-space: normal;
		}
	}
}

.left-align {
	text-align:left;
}

.right-align {
	text-align: right;
}