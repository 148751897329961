section {
	//min-height: 100vh;
	//padding: 5rem 0;


	&.middle {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}


	@include screen(lg) {
		.mobile-frame {
			height: 100%;
			.row {
				height: 100%;
			}

			&__between {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
}