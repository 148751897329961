.site-header {
	width: 100%;
	z-index: 3;
	position: absolute;
	top: 0;
	left: 0;
	max-width: 20rem;
	
	img {
		width: 100%;
		height: auto;
	}
}