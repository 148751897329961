//Colors
$alpha-md : #D24600;

$beta-md : #0B485B;

$shade-md : #333333;

//Fonts
$sans : 'Roboto', sans-serif;

//Site width
$max : 75rem;

// Breakpoints
$screen-lg : 64rem; //1080px
$screen-md : 47.9375rem; //767px
$screen-sm : 40rem; //640px
$screen-xs : 30rem; //480px

@mixin screen($size) {
  @if $size == lg {
    @media (max-width: $screen-lg) { @content; }
  }
  @else if $size == md {
    @media (max-width: $screen-md) { @content; }
  }
  @else if $size == sm {
    @media (max-width: $screen-sm)  { @content; }
  }
  @else if $size == xs {
    @media (max-width: $screen-xs)  { @content; }
  }
}
