@function _rem($pixels : 16) {
	@return ($pixels / 16) * 1rem;
}

@function _leading($fontSize : 35, $designLineHeight : 45) {
	//multiple size by document size disparity, return
	@return ($designLineHeight) / ($fontSize);
}

@function _tracking($designTrackingValue : 10) {
	//convert their tracking numbers into EMs (since it is based on text size)
	$inEMs : $designTrackingValue / 1000;
	//multiple size by document size disparity, return
	@return $inEMs * 3em;
}