.bg-overlay {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba($beta-md, .8);
		z-index: -1;
	}
}