.alpha-theme {
	background-color: $alpha-md;
	color: #fff;
}

.alpha {
	color: $alpha-md;
}

.beta-theme {
	background-color: $beta-md;
	color: #fff;
}

.beta {
	color: $beta-md;
}