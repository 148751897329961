.intro {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;

	&.gradient {
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgb(11,72,91);
			background: linear-gradient(0deg, rgba(11,72,91,.8) 0%, rgba(11,72,91,0) 100%);
			z-index: 0;
		}
	}

	&__video {
		z-index: -1;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: -1;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}

	&__arrow {
		position: absolute;
		display: block;
		color: #fff;
		bottom: 3rem;
		left: 50%;
		animation: bounce 2s infinite ease-in-out;
		transform: translate(0, -50%);
		width: 3.05rem;
		height: 1.5rem;
		background: url('../images/scroll-indicator.svg') center center no-repeat;
		background-size: cover;

		@include screen(sm) {
			bottom: 2rem;
			background-image: url('../images/graphic-mobile-nav.svg');
			background-size: 2.5rem;
			height: 2.5rem;
			animation: none;
			transform: translateX(-50%) rotate(180deg);
		}
	}
}