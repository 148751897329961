.social {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__button {
		width: 1.75rem;
		height: 1.75rem;
		background: center center no-repeat;
		background-size: cover;
		transition: transform 0.2s ease-in-out;

		&:hover {
			transform: translateY(-.25rem);
		}

		&:not(:last-child) {
			margin-right: 1.2rem;
		}

		&.facebook {
			background-image: url('../images/social-facebook.svg');
		}
		&.twitter {
			background-image: url('../images/social-twitter.svg');
		}
		&.instagram {
			background-image: url('../images/social-instagram.svg');
		}
		&.linkedin {
			background-image: url('../images/social-linkedin.svg');
		}
		&.youtube {
			width: 2.5rem;
			background-image: url('../images/social-youtube.svg');
		}
	}
}