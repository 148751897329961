.secondary-button {
	padding: 1rem 0;
	font-size: 1.25rem;
	font-weight: 700;
	text-decoration: none;
	letter-spacing: _tracking(20);
	border: none;
	display: inline-block;
	color: #fff;

	&.arrow {
		position: relative;
		padding-right: 3rem;

		&:after {
			content:'';
			width: 1rem;
			height: 1rem;
			background: url('../images/graphic-link-arrow.svg') center center no-repeat;
			background-size: cover;
			position: absolute;
			top: 50%;
			transform: translate( 0, -50%);
			transition: all 0.25s ease-out;
			right: 1.5rem;
		}

		@include screen(lg) {
			padding-right: 1.5rem;

			&:after {
				right: 0;
			}
		}

		&:hover {
			&:after {
				transform: translate( .5rem, -50%);
			}
		}
	}
}