.follow-scroll {
	position: relative;
	overflow: hidden;
	z-index: 1;

	&__frame {
		min-height: 100vh;
	}

	&__background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;

		background-size: cover;
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-color: #000;
	    z-index: -1;

	    &.follow {
	    	position: absolute;
	    	top: 100vh;
	    }

	    video {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}
}