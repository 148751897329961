.carousel {
	$height : _rem(365);
	overflow: hidden;
	width: $height;
	height: $height;

	&-container {
		display: inline-block;
	}

	img {
		width: 100%;
	}

	&__controls {
		width: $height;
		margin-top: .5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			width: 1rem;
			height: 1rem;
			// background-color: #fff;
			background-color: transparent;
			border: .125rem solid #fff;
			appearance: none;
			border-radius: 1.5rem;
			transition: background-color 0.3s ease-out;

			&:not(:last-child) {
				margin-right: 1rem;
			}

			&.current {
				background-color: #fff;
			}
		}
	}

	&__ad {
		height: $height;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgba($beta-md, .9);

		& > div {
			max-width: _rem(245);
		}

		p {
			font-size: _rem(25);
			line-height: _leading(25, 32);
		}
	}

	@include screen(lg) {
		display: none;

		&__controls {
			display: none;
		}
	}
}