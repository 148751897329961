.mobile-nav-button {
	display: none;

	@include screen(lg) {
		display: block;
		margin: 1.25rem 0 1rem;
		width: 100%;
		height: 2.5rem;
		background: url('../images/graphic-mobile-nav.svg') center center no-repeat;
		background-size: 2.5rem;

		&.next {
			transform: rotate(180deg);
			margin: 2.5rem 0 1.25rem;
		}
	}
}


