.site-footer {
	background: $shade-md;
	padding: 4rem 0;

	&__row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&--external-links {
		flex-direction: row;
		align-items: center;
	}

	@include screen(md) {
		&__row {
			flex-direction: column;
			justify-content: center;
		}

		&--external-links {
			padding: 2rem 0;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&> div {
				text-align: center;
			}
		}
	}

	
}