body,html {
	margin: 0;
	font-family: $sans;
	scroll-behavior: smooth;
	background-color: $beta-md;
	overflow-x: hidden;
}

div, p, input, button, a, ul, li, ol {
	box-sizing: border-box;
}

.dashboard {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 1rem;
	background: #121212;
	color: #fff;
	font-size: 1rem;
	z-index: 99;
}